import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "contrast" }
const _hoisted_2 = { class: "is-size-5" }
const _hoisted_3 = { class: "highlight" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("span", null, _toDisplayString(_ctx.content), 1),
      _createVNode("span", _hoisted_3, _toDisplayString(_ctx.highlight), 1)
    ]),
    _createVNode("div", null, _toDisplayString(_ctx.comment), 1)
  ]))
}