
import { defineComponent } from "vue";
import VImage from "@/components/Image.vue";
import VSection from "@/components/VSection/index.vue";

export default defineComponent({
  name: "Description",

  components: {
    VImage,
    VSection,
  },

  props: {
    image: {},
    title: {},
    subTitle: {},
    features: {}
  }
});
