
import { defineComponent } from "vue";
import VImage from "@/components/Image.vue";
import VSection from "@/components/VSection/index.vue";
import { enUS, zhCN } from "@/plugins/i18n";

import axios from "axios";

export default defineComponent({
  name: "TheHero",
  data () {
    return {
      isShow: false,
      record: {
        phone: "",
        content: ""
      }
    };
  },
  components: {
    /* VImage, */
    VSection
  },
  methods: {
    show () {
      this.isShow = true;
    },
    hide () {
      this.isShow = false;
      this.record = {
        phone: "",
        content: ""
      };
    },
    handleOk () {
      if (this.record.phone === undefined || this.record.phone === "") {
        alert(this.$t("lxfspls"));
        return;
      }
      if (!this.checkMobile(this.record.phone)) {
        alert(this.$t("lxfszqpls"));
        return;
      }
      // 发送邮件
      const prefix = this.$route.query.test === "1" ? "http://10.110.1.71:9099/mail" : "http://120.55.49.252:81/api/mail";
      axios.post(`${prefix}/sendMail`, this.record).then(() => {
        // 下载视频
        const a = document.createElement("a");
        a.setAttribute("href", "/static/玉串垂直物流输送系统 规划实战教程.mp4");
        a.setAttribute("download", this.$t("videoName"));
        document.body.appendChild(a);
        a.click();

        this.hide();
      }).catch(err => alert(err));
    },
    checkMobile (mobile = "") {
      const re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      const result = re.test(mobile);
      if (!result) {
        return false;
      }
      return true;
    }
  },
  props: {
    reversed: Boolean
  },

  i18n: {
    messages: {
      [zhCN]: {
        title: "垂直物流输送系统",
        content: "垂直物流输送是在指多层建筑结构中，以提升机为主的各种输送设备进行作业， 信息以及控制的组合实现货物水平和垂直自由搬运的一种自动非载人精密设备。",
        feat1: "搬运能力是货梯的 <strong class=\"is-size-5 has-text-danger\">3倍</strong>",
        feat2: "独立塔体，使用寿命长，操作安全、简单，广泛应用于仓库、生产线和配送中心",
        feat3: "结合输送机、调度系统、控制系统和其他设备，实现自动化跨楼层输送",
        qyalspxz: "企业案例视频下载",
        lxfs: "联系方式",
        lxfspls: "请填写联系方式",
        lxfszqpls: "请填写正确的联系方式",
        xqnr: "需求内容",
        xqnrpls: "请填写需求内容",
        wsxx: "完善信息即可立即下载",
        videoName: "玉串垂直物流输送系统 规划实战教程.mp4",
        lang: "zh",

      },
      [enUS]: {
        title: "Vertical logistics delivery system.",
        content: "Vertical logistics transport is a kind of automatic and non-manned precision equipment for horizontal and vertical free transport of goods in multi-storey building structures, which is mainly composed of various transport equipment such as elevators for operation, information and control.",
        feat1: "The carrying capacity is <strong class=\"is-size-5 has-text-danger\">Three</strong> times than the freight elevator.",
        feat2: "Independent tower, long life, safe and simple operation, widely used in warehouses, production lines and distribution centers.",
        feat3: "Combine conveyor, dispatch system, control system and other equipment to realize automatic transport across floors.",
        qyalspxz: "Enterprise case video download",
        lxfs: "contact information",
        lxfspls: "Please fill in the contact information",
        lxfszqpls: "Please fill in the correct contact information",
        xqnr: "requirement content",
        xqnrpls: "Please fill in the requirement content",
        wsxx: "Complete the information and download it immediately",
        videoName: "Yuchuan Vertical Logistics Transportation System Planning Practical Tutorial.mp4",
        lang: "en",
      }
    }
  }
});
