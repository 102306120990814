
import { defineComponent } from "vue";
import { enUS, zhCN } from "@/plugins/i18n";
import VHero from "@/components/Hero/index.vue";
import VImage from "@/components/Image.vue";
import VSection from "@/components/VSection/index.vue";
import TheAdvantages from "./TheAdvantages.vue";
import TheDrumpType from "./TheDrumpType.vue";
import TheSigmaType from "./TheSigmaType.vue";
import TheTransplotRoutes from "./TheTransplotRoutes.vue";
import ThePLType from "./ThePLType.vue";
import TheCases from "./TheCases.vue";
import TheHero from "./TheHero.vue";
import TheTechniques from "./TheTechniques.vue";

export default defineComponent({
  name: "Hostier",

  components: {
    VHero,
    VImage,
    VSection,
    TheHero,
    TheCases,
    TheAdvantages,
    TheDrumpType,
    TheSigmaType,
    ThePLType,
    TheTransplotRoutes,
    TheTechniques,
  },

  data: () => ({
    tabs: [
      { text: "model1", component: "TheDrumpType" },
      { text: "model2", component: "ThePLType" },
      // { text: "model3", component: "TheSigmaType" }
    ],
    currentTab: 0
  }),

  i18n: {
    messages: {
      [zhCN]: {
        type: "垂直物流输送系统机型",
        model1: "标准托盘搬运型",
        model2: "专用托盘循环搬运型",
        // model3: "Σ循环型",
      },
      [enUS]: {
        type: "LIFT Automatic Logistics System",
        model1: "Standard Pallet Type",
        model2: "Special pallet circulation type",
        // model3: "Σ Cycle",
      }
    }
  }
});
