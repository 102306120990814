import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "columns is-centered is-multiline" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-image" }
const _hoisted_4 = { class: "image is-5by4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_VImage = _resolveComponent("VImage")
  const _component_v_section = _resolveComponent("v-section")

  return (_openBlock(), _createBlock(_component_v_section, {
    title: _ctx.$t('title'),
    style: {"padding-top":"0"}
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.images, (path) => {
          return (_openBlock(), _createBlock("div", {
            class: "column is-4",
            key: path
          }, [
            _createVNode("div", _hoisted_2, [
              _createVNode("div", _hoisted_3, [
                _createVNode("figure", _hoisted_4, [
                  _createVNode(_component_VImage, { path: path }, null, 8, ["path"])
                ])
              ])
            ])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}