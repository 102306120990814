import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheDescription = _resolveComponent("TheDescription")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_TheDescription, {
      title: _ctx.title,
      "sub-title": _ctx.subTitle,
      image: "hoisters/sigma/animation.gif",
      features: _ctx.features
    }, null, 8, ["title", "sub-title", "features"])
  ]))
}