
import { defineComponent } from "vue";
import { enUS, zhCN } from "@/plugins/i18n";
import VSection from "@/components/VSection/index.vue";
import TheTransplotRouteTypeSVG from "./TheTransplotRouteTypeSVG.vue";

const routes = [
  "Z", "C", "CC", "CZ", "ZC", "F",
  "E", "ZE", "FE", "EE", "LF", "LL"
];

export default defineComponent({
  name: "TransplotRoutes",

  components: {
    VSection,
    TheTransplotRouteTypeSVG
  },

  computed: {
    routes: () => routes
  },

  i18n: {
    messages: {
      [zhCN]: {
        title: "出入口方向",
        type: " 型"
      },
      [enUS]: {
        title: "Door Direction",
        type: "-Type"
      }
    }
  }
});
